import React from 'react'
import styles from '../BottomPanel/BottomPanel.module.scss'

const BottomPanel = () => {
    return (
    <div className={styles.bottomPanel}>
        <div className={styles.bottomPanelWrap}>
            <div className={styles.panelColumn}>
                <h3>Про салон:</h3>
                <div>
                    <p>
                        Салон краси «Нія» вітає Вас!
                        <br/>
                        Ми знаходимося за адресою: м.Зіньків, вул.Погрібняка, 5-А
                    </p>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Години роботи салону:</strong>
                                </td>
                                <td>
                                    <strong>Детальна інформація за тел:</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>
                                        <strong>Пн. - Сб.:</strong>
                                        09:00 - 18:00
                                        <br/>
                                        <strong>Нед.:</strong>
                                        09:00 - 15:00
                                    </p>
                                </td>
                                <td>
                                    <p>
                                        +38 (068) 777-58-58
                                        <br/>
                                        +38 (066) 307-70-52
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className={styles.panelColumn}>
                <h3 class="widget-title m-secondary-font">Розсилка новин та акцій</h3>
                <div class="textwidget">
                    <p>
                    Нажмите на кнопку "Подписаться на новые Акции" для того, чтобы узнать раньше всех о наших Новостях или Акциях. 
                    </p>
                </div>
            </div> */}
        </div>
    </div>     
    )
}

export default BottomPanel