import React from 'react'
import s from './Title.module.scss'
import { Link } from 'react-router-dom'

const Title = (props) => {
    const numberParam = Object.keys(props).length;
    if(numberParam < 2)
        return (
            <div className={s.container}>
                <h1 className={s.nameOfPage}>{props.name}</h1>
                <div className={s.links}>
                    <Link to='/' className={s.mainPage}>Головна</Link>
                    <span className={s.slash}>/</span>
                    <span className={s.carrentPage}>{props.name}</span>
                </div>
            </div>
        )
    else{
        return (
            <div className={s.container}>
                <h1 className={s.nameOfPage}>{props.name}</h1>
                <div className={s.links}>
                    <Link to='/' className={s.mainPage}>Головна</Link>
                    <span className={s.slash}>/</span>
                    <Link to={props.previousPage} className={s.previousPage}>Попередня</Link>
                    <span className={s.slash}>/</span>
                    <span className={s.carrentPage}>{props.carrentP}</span>
                </div>
            </div>
        )
    }
}

export default Title