import React, { useEffect, useState } from 'react'
import Title from '../../components/Title/Title'
import styles from './ProductPage.module.scss'
import {useParams, useLocation} from 'react-router-dom'
import { fetchOneDevice } from '../../http/deviceAPI'
const ProductPage = () => {
    const [device, setDevice] = useState({info: []})
    const {id} = useParams()
    useEffect(() => {
        fetchOneDevice(id).then(data => setDevice(data))
    }, [])

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);
    return (
        <div className="page-container" id="product">
            <Title name='Каталог товарів' previousPage="/device" carrentP={device.name}/>
            <div className={styles.container}>
                <div className={styles.mainInfo}>
                    <div className={styles.img}>
                        <img width={300} height={300} src={process.env.REACT_APP_API_URL + device.img} alt="fds"/>
                    </div>
                    <div className={styles.information}>
                        <h2>{device.name}</h2>
                        <br/>
                        <span className={styles.price}>{device.price} грн</span>
                    </div>
                </div>
                <div className={styles.details}>
                    <h2>ОПИС</h2>
                    {device.info.map(info => 
                        <p key={info.id}>
                            <strong>{info.title}</strong>: {info.description}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}


export default ProductPage