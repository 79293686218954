import BottomPanel from './components/BottomPanel/BottomPanel';
import Header from './components/Header/Header'
import Main from './pages/Main/Main'
import About from './pages/About/About'
import Services from './pages/Services/Services'
import News from './pages/News/News'
import Goods from './pages/Goods/Goods'
import Contacts from './pages/Contacts/Contacts'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer/Footer'
import ProductPage from './pages/ProductPage/ProductPage';
import ScrollToTop from './ScrollToTop';

function App() {

  return (
    <Router>
      <div>
        <Header />
        <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/news' element={<News />} />
          <Route path='/device' element={<Goods />} />
          <Route path='/device/:id' element={<ProductPage />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/*' element={<Main />} />
        </Routes>
        <BottomPanel />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
