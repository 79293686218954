import { React, useState, useEffect } from 'react'
import logoImage from '../../assets/img/logo.png'
import { menu } from './menu'
import { Link , useLocation} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook , faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope , faPhone, faClock, faMapMarked, faBars, faArrowDown, faArrowUp} from '@fortawesome/free-solid-svg-icons'

import styles from './Header.module.scss'

const Header = () => {
  const location = useLocation()
  const getColor=(curr)=>{
    if(location.pathname===curr){
      return "green"
    }
  }
  const [menuOpen, setMenuOpen] = useState(false);
  const [contactsOpen, setContactsOpen] = useState(false);
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if(size.width > 1200){
      setMenuOpen(false);
      setContactsOpen(false);
    }
  }, [size.width, menuOpen, contactsOpen])


  return (
    <header className={styles.header}>
      <div className={styles.headerInner}>
        <div className={styles.logo}>
          <img src={logoImage} alt=""/>
        </div>
        <nav className={styles.menuWrapper}>
          <button 
            className={styles.toggleButton}
            onClick={() => setMenuOpen(!menuOpen)}>
            <FontAwesomeIcon icon={faBars} />
            <span>МЕНЮ</span>
          </button>
          <ul className={`${styles.menu}`} style={menuOpen ? {display: "none"} : {display: "block"}}>
            {menu.map((item, idx)=>(
              <li key={`menu item ${idx}`}>
                <span>
                  <Link to={{ pathname: item.link, hash: item.hash}} style={{color:getColor(item.link)}}>{item.title}</Link>
                </span>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className={styles.underMenuWrapper}>
        <div className={styles.headerContacts} style={contactsOpen ? {display: "none"} : {display: "grid"}}>
          <div className={styles.contactItem}>
            <FontAwesomeIcon icon={faPhone} />
            <p>
              <a href="tel:0687775858" >+38 (068) 777-58-58</a>
              <br/>
              <a href="tel:0663077052" >+38 (066) 307-70-52</a>
            </p>
          </div>
          <div className={styles.contactItem}>
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="https://www.google.com/intl/uk/gmail/about/">nia@gmail.com</a>
          </div>
          <div className={styles.contactItem}>
            <FontAwesomeIcon icon={faMapMarked} />
            <p>
              <strong>САЛОН НІЯ</strong>
              <br/>
              Зіньків, вулиця Погрібняка, 5-A
            </p>
          </div>
          <div className={styles.contactItem}>
            <FontAwesomeIcon icon={faClock} />
            <p>
              Пн. - Сб.: 09:00 - 18:00
              <br/>
              Нед.: 09:00 - 15:00
            </p>
          </div>
        </div>
        <div className={styles.headerSocial} style={contactsOpen ? {display: "none"} : {display: "flex"}} >
          <a className={styles.sotialIcon} href="https://www.facebook.com/niiabeautyz/photos/?ref=page_internal" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a className={styles.sotialIcon} href="https://www.instagram.com/niia_beautyz/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a className={styles.sotialIcon} href="https://www.google.com/intl/uk/gmail/about/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
        <button 
          className={styles.toggleButtonBottom}
          onClick={() => setContactsOpen(!contactsOpen)}>
          {contactsOpen ? (
          <FontAwesomeIcon icon={faArrowDown}/>
          )  : (
          <FontAwesomeIcon icon={faArrowUp} />
          )}
        </button>
      </div>
    </header>
  )
}

export default Header