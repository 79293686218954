import { galleryData } from "../arrImages";

const Modal = ({clickedImg, currentIndex, currentDescription, handelRotationRight, handelRotationLeft, setClickedImg}) => {

    const handelClick = (e) => {
        if(e.target.classList.contains("dismiss")){
            setClickedImg(null);
        }
    }
    return <>
    <div className="overlay dismiss" onClick={handelClick}>
        <img src={clickedImg} alt="bigger pictrure" onClick={handelRotationRight}/>
        <span className="dismiss" onClick={handelClick}>X</span>
        <div onClick={handelRotationLeft} className="overlay-arrows_left">
            <div>
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="50" 
                    height="50" 
                    fill="currentColor" 
                    class="bi bi-arrow-left" 
                    viewBox="0 0 16 16">
                    <path 
                        fill-rule="evenodd" 
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                    />
                </svg>
            </div>
        </div>
        <div onClick={handelRotationRight} className="overlay-arrows_right">
            <div>
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="50" 
                    height="50" 
                    fill="currentColor" 
                    class="bi bi-arrow-right" 
                    viewBox="0 0 16 16">
                    <path 
                        fill-rule="evenodd" 
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                </svg>
            </div>
        </div>
        <div className="overlay-title">{currentDescription}</div>
        <div className="overlay-counter">{currentIndex+1}/{galleryData.length}</div>
    </div>
    </>
}

export default Modal;