import React from 'react'
import styles from '../Footer/Footer.module.scss'

const Footer = () => {
    return (
    <footer className={styles.footer}>
        <div class={styles.footerText}>
            <p>
                2022 © Салон краси Нія в Зінькові
            </p>
        </div>
    </footer>
    )
}

export default Footer