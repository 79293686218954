import React, {useContext, useEffect} from 'react'
import {observer} from "mobx-react-lite"
import {Context} from "../../index"
import styles from "./DeviceList.module.scss"
import {useNavigate, useLocation} from "react-router-dom"

const DeviceList = observer(() => {

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

    const {device} = useContext(Context)
    const navigate = useNavigate()
    return (
        <div className={styles.container} id='device'>
            {device.devices.map(device => 
                <div className={styles.item} key={device.id} onClick={() => navigate('/device/' + device.id + '#product')}>
                    <img className={styles.itemImg} width={150} height={150} src={process.env.REACT_APP_API_URL + device.img} alt="dsf"/>
                    <h3 className={styles.itemName}>{device.name}</h3>
                    <br/>
                    <span className={styles.itemPrice}>{device.price}грн</span>
                </div>
            )}
        </div>
    );
});

export default DeviceList