import {React, useEffect} from 'react'
import Title from '../../components/Title/Title'
import styles from './About.module.scss'
import mainImage from '../../assets/img/mainImage.png'
import '../pages.css'
import {useLocation } from 'react-router-dom'

const About = () => {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <div className='page-container' id='about'>
      <Title name='Про салон'/>
      <div className={styles.body}>
        <h2 className="sectionTitle"><img src={mainImage} alt=""/>САЛОН КРАСИ «НІЯ»</h2>
        <p>Колектив салону краси «Нія» запрошує Вас у світ краси, гармонії й здоров’я.</p>
        <h3>МИ ЗНАЄМО ЯК ПІДКРЕСЛИТИ ВАШУ ДОCКОНАЛІСТЬ</h3>
        <p>
          Головне призначення – піклування про Вашу красу, молодість і здоров’я, 
          про Ваш комфорт і гарний настрій. І нам дійсно вдається робити життя наших клієнтів яскравішим і комфортнішим!
          Ваше бажання в гармонії з нашою турботою про Вас – це Ваша краса і Ваше здоров’я!
        </p>
        <p>
          Так що ж робить наш салон особливим і не схожим на інші салони краси? Чому клієнти обирають саме салон краси «Нія»?
          Закладаючи основи нашого салону краси, ми доклали всіх зусиль для того, щоб кожен, без винятку, клієнт отримав максимум комфорту
          і задоволення від професійного догляду та уважного ставлення, і насолодився прекрасним результатом чарівних перетворень!
          Саме тому в «Нії» ми об’єднали головні складові якісного салонного обслуговування: 
          Комфортний і ненав’язливий сервіс, а також персональний підхід до кожного клієнта,
          з урахуванням навіть самих незначних індивідуальних особливостей і побажань. 
        </p>
        <p>
          Ми любимо свою роботу і робимо її з душею. Завдяки цьому, між клієнтами і майстрами нашого салону краси виникають
           теплі довірчі відносини. Саме ці якості спонукають наших клієнтів приходити до нас ще і ще, і приводити з собою
            родичів, друзів і знайомих.
        </p>
        <p>
          Ми розвиваємося і вдосконалюємося, щоб Ви змогли отримати не тільки широкий асортимент перевірених часом
           послуг і процедур. Абсолютна безпека послуг і процедур в поєднанні з бездоганною якістю виконання.
          З моменту свого створення, наш салон пропонує Вам тільки найбезпечніші послуги, виконані на високоякісному рівні, адже
          ви приходите до нас саме за цим! Особливу увагу ми приділяємо стерильності, чистоті інструментів і приладів, 
          адже це запорука здоров’я наших клієнтів, і бездоганність репутації нашого салону.
        </p>
        <p>
          Завдяки цьому наші постійні клієнти повністю довіряють нам турботу про свою зовнішність і здоров’я, 
          а ми робимо все можливе, щоб виправдати цю довіру. Ми будемо щиро раді, якщо завдяки нашій турботі і 
          професійному догляду, Ви зможете підкреслити свою природність і індивідуальність, 
          змінити на краще не тільки зовнішність, а й стиль життя. 
          Прийшовши в салон краси «Нія», Ви оціните те, як легко досягти Краси, Молодості і Здоров’я,
          а всі інші на власні очі переконаються в цьому! Адже балувати себе так приємно!
        </p>
      </div>
    </div>
  )
}

export default About