import React from 'react'
import Title from '../../components/Title/Title'
import '../pages.css'
import styles from './Services.module.scss'
import {sliderFirstData} from '../Main/arrImages'
import { useEffect } from 'react'
import {useLocation } from 'react-router-dom'
import {childrensHaircuts, hotBotox, womensHaircuts, cauterization, hairstyles, keratinSmoothing, intensiveRecovery, coldBotox, mensHaircuts, dyeing, pedicureWithCoating, modeling, varnishCoating, perm, eveningMakeup} from './arrServiceImages'

const Services = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <div className="page-container" id='services'>
      <Title name='Наші послуги'/>
      <div className={styles.variousContent}>
        <section id="heirdo">
          <h2 className="sectionTitle">Перукарські послуги</h2>
          <div className={styles.content}>
            <img className={styles.contentImg} src={sliderFirstData[0].image} alt="фото"/>
            <div className={styles.contentDetails}>
              <details>
                <summary>Дитячі стрижки</summary>
                <div className={styles.detailsItems}>
                  {childrensHaircuts.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Дитячі стрижки – це окремий вид перукарського мистецтва.
                    Майстер повинен бути не тільки професіоналом, а й тонким психологом,
                    аби знайти особливий підхід до маленького клієнту.
                    Адже якщо за волоссям правильно доглядати з самого дитинства,
                     вони будуть сильними та здоровими протягом усього життя.</p>
                </div>
              </details>
              <details>
                <summary>Гарячий ботокс</summary>
                <div className={styles.detailsItems}>
                  {hotBotox.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Гарячий ботокс призначений для тих, кого крім оздоровлення цікавить
                     ще й випрямлення кучерявих, неслухняних, пухнастих пасм. 
                     Після процедури волосся стає рівним і гладким, майже як після кератинового випрямлення.</p>
                </div>
              </details>
              <details>
                <summary>Жіночі стрижки</summary>
                <div className={styles.detailsItems}>
                  {womensHaircuts.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Зачіска – це один із тих «трьох китів», що створюють неповторний, елегантний,
                     чарівний образ жінки. Допомогти знайти «ту саму себе», втілити мрію у реальність
                      – завдання для майстра гребінців і ножиць у Нії</p>
                </div>
              </details>
              <details>
                <summary>Каутеризація</summary>
                <div className={styles.detailsItems}>
                  {cauterization.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Це лікувально-естетична процедура, метою якої, перш за все, 
                    є відновлення пошкодженої структури, глибоке харчування і 
                    «запечатування» кутикули кожної волосинки. Якщо сказати по-науковому, 
                    каутеризація займається реструктуризацією волосся.</p>
                </div>
              </details>
              <details>
                <summary>Зачіски</summary>
                <div className={styles.detailsItems}>
                  {hairstyles.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Зачіска – це фінальний штрих усього образу. Вона повинна бути індивідуальною,
                     неповторною, а ще - зручною та гармонійною. Ми підбираємо зачіску під гостя
                      і його індивідуальні собливості. Ми маємо на меті підкреслити натуральну
                       красу та природність вашого волосся.</p>
                </div>
              </details>
              <details>
                <summary>Кератинове вирівнювання</summary>
                <div className={styles.detailsItems}>
                  {keratinSmoothing.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Це складна процедура, яка виконується за допомогою багатокомпонентних
                     професійних засобів. Ефект від кератинування помітний відразу і
                      зберігається протягом тривалого часу. Майстри нашого салону
                       готові зробити ваше волосся слухняним і м'яким. Запишіться на процедуру,
                        аби залишити норовливі кучері в минулому.</p>
                </div>
              </details>
              <details>
                <summary>Інтенсивне відновлення</summary>
                <div className={styles.detailsItems}>
                  {intensiveRecovery.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p></p>
                </div>
              </details>
              <details>
                <summary>Холодний ботокс</summary>
                <div className={styles.detailsItems}>
                  {coldBotox.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Холодний ботокс - це щадна процедура по оздоровленню кутикулярного шару волосся.
                     Її головна особливість полягає в технології без використання гарячого прасування,
                      тому процедура підходить навіть самим ушкодженому волоссю.</p>
                </div>
              </details>
              <details>
                <summary>Чоловічі стрижки</summary>
                <div className={styles.detailsItems}>
                  {mensHaircuts.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Красиві чоловіки завжди в тренді, а 
                    хіба можливий цілісний образ сильного і впевненого 
                    у собі чоловіка без відповідної, доречної, модної зачіски?</p>
                  <p>Найточніший спосіб дізнатися вартість послуг перукаря – відвідати салон,
                     поспілкуватися з майстром, обговорити те, які стрижки подобаються, 
                     пасують до зовнішності та іміджу.</p>
                </div>
              </details>
              <details>
                <summary>Фарбування</summary>
                <div className={styles.detailsItems}>
                  {dyeing.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Фарбування -  це ще одна популярна процедура, що дозволяє зробити
                     образ більш яскравим та цікавішим. Майстри салону краси Нія мають 
                     і професійну підготовку і великий досвід в цьому питанні.</p>
                </div>
              </details>
              <details>
                <summary>Хімічна завивка</summary>
                <div className={styles.detailsItems}>
                  {perm.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Хімічна завивка - перукарна процедура формування кучерів і 
                    додаткового об’єму волосся за допомогою моделюючих складів і
                     спеціальних бігуді. Завитки формуються як по всій довжині, так і
                      на кінчиках пасмів, або в прикорневій зоні. Хімічна 
                      завивка відрізняється високою стійкістю і при правильному догляді 
                      тримається до 6 місяців, а іноді навіть довше.</p>
                </div>
              </details>
            </div>
          </div>
        </section>
        <section id="manicure">
          <h2 className="sectionTitle">Манікюр</h2>
          <div className={styles.content}>
            <img className={styles.contentImg} src={sliderFirstData[1].image} alt="фото"/>
            <div className={styles.contentDetails}>
              <details>
                <summary>Моделювання</summary>
                <div className={styles.detailsItems}>
                  {modeling.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p></p>
                </div>
              </details>
              <details>
                <summary>Покриття гель лаком</summary>
                <div className={styles.detailsItems}>
                  {varnishCoating.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p></p>
                </div>
              </details>
            </div>
          </div>
        </section>
        <section id="pedicure">
          <h2 className="sectionTitle">Педикюр</h2>
          <div className={styles.content}>
            <img className={styles.contentImg} src={sliderFirstData[2].image} alt="фото"/>
            <div className={styles.contentDetails}>
              <details>
                <summary>Педикюр з покриттям</summary>
                <div className={styles.detailsItems}>
                  {pedicureWithCoating.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p></p>
                </div>
              </details>
            </div>
          </div>
        </section>
        <section id="makeup">
          <h2 className="sectionTitle">Візаж</h2>
          <div className={styles.content}>
            <img className={styles.contentImg} src={sliderFirstData[3].image} alt="фото"/>
            <div className={styles.contentDetails}>
              <details>
                <summary>Макіяж вечірній</summary>
                <div className={styles.detailsItems}>
                  {eveningMakeup.map((item)=>(
                    <img className={styles.itemPhoto} src={item.image} alt="фото"/>
                  ))}
                  <p>Професійний візаж здатен не тільки кардинально змінити зовнішній вигляд
                     будь якої жінки — він може подарувати їй можливість сяяти на будь-якому
                      урочистому заході, а також стати зіркою в повсякденному житті.</p>
                </div>
              </details>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Services