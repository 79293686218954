import React, { useState } from 'react'
import {galleryData } from '../arrImages'
import styles from './Gallery.module.scss'
import Modal from './Modal'

const Gallery = () => {
    const [clickedImg, setClickedImg] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [currentDescription, setDescription] = useState(null);

    const handelClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.image);
        setDescription(item.description)
    };

    const handelRotationRight = () => {
        const totalLength = galleryData.length;
        if(currentIndex +1 >= totalLength){
            setCurrentIndex(0);
            const newUrl = galleryData.data[0].image;
            setClickedImg(newUrl);
            const newDescr = galleryData.data[0].description;
            setClickedImg(newDescr);
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = galleryData.filter((item) => {
            return galleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].image;
        const newDescr = newUrl[0].description;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
        setDescription(newDescr)
    }

    const handelRotationLeft = () => {
        const totalLength = galleryData.length;
        if(currentIndex -1 < 0){
            setCurrentIndex(totalLength-1);
            const newUrl = galleryData.data[totalLength-1].image;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = galleryData.filter((item) => {
            return galleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].image;
        setClickedImg(newItem);
        setCurrentIndex(newIndex);
    }

  return (
        <div className={styles.galleryList}>
            {galleryData.map((item)=>(
                <div className={styles.galleryItem} onClick={() => handelClick(item, item.id, item.description)}>
                    <div className={styles.galleryItemHover}></div>
                    <img src={item.image} alt=""/>
                </div>
            ))}
            {clickedImg && (
                <Modal
                    clickedImg={clickedImg}
                    currentIndex={currentIndex}
                    currentDescription={currentDescription}
                    handelRotationRight={handelRotationRight}
                    handelRotationLeft={handelRotationLeft}
                    setClickedImg={setClickedImg}
                />
            )}
        </div>
    )
}

export default Gallery