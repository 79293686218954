import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import DeviceList from '../../components/DeviceList/DeviceList'
import Title from '../../components/Title/Title'
import '../pages.css'
import styles from './Goods.module.scss'
import {Context} from "../../index"
import { fetchDevices } from '../../http/deviceAPI'
import {useLocation } from 'react-router-dom'

const Goods = observer(() => {
  const {device} = useContext(Context)

  useEffect(() => {
    fetchDevices().then(data => device.setDevices(data.rows))
  })

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <div className="page-container" id='device'>
      <Title name='Каталог товарів'/>
      <div className={styles.goodsContainer}>
        <DeviceList />
      </div>
    </div>
  )
})

export default Goods