export const childrensHaircuts = [
    {
        id: 1,
        image: "images/servicesImages/childrensHaircuts1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/childrensHaircuts2.jpg",
    },
    {
        id: 3,
        image: "images/servicesImages/childrensHaircuts3.jpg",
    },
]


export const hotBotox = [
    {
        id: 1,
        image: "images/servicesImages/hotBotox1.jpg",
        description: "Салон Нія",
    },
]

export const womensHaircuts = [
    {
        id: 1,
        image: "images/servicesImages/womensHaircuts1.jpg",
        description: "Нові процедури в салоні",
    },
    {
        id: 2,
        image: "images/servicesImages/womensHaircuts2.jpg",
        description: "Нові процедури в салоні",
    },
]

export const cauterization = [
    {
        id: 1,
        image: "images/servicesImages/cauterization1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/cauterization2.jpg",
    },
]

export const hairstyles = [
    {
        id: 1,
        image: "images/servicesImages/hairstyles1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/hairstyles2.jpg",
    },
    {
        id: 3,
        image: "images/servicesImages/hairstyles3.jpg",
    },
]

export const keratinSmoothing = [
    {
        id: 1,
        image: "images/servicesImages/keratinSmoothing1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/keratinSmoothing2.jpg",
    },
]

export const intensiveRecovery = [
    {
        id: 1,
        image: "images/servicesImages/intensiveRecovery1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/intensiveRecovery2.jpg",
    },
]

export const coldBotox = [
    {
        id: 1,
        image: "images/servicesImages/coldBotox1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/coldBotox2.jpg",
    },
    {
        id: 3,
        image: "images/servicesImages/coldBotox3.jpg",
    },
    {
        id: 4,
        image: "images/servicesImages/coldBotox4.jpg",
    },
]

export const mensHaircuts = [
    {
        id: 1,
        image: "images/servicesImages/mensHaircuts1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/mensHaircuts2.jpg",
    },
]

export const dyeing = [
    {
        id: 1,
        image: "images/servicesImages/dyeing1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/dyeing2.jpg",
    },
    {
        id: 3,
        image: "images/servicesImages/dyeing3.jpg",
    },
    {
        id: 4,
        image: "images/servicesImages/dyeing4.jpg",
    },
    {
        id: 5,
        image: "images/servicesImages/dyeing5.jpg",
    },
    {
        id: 6,
        image: "images/servicesImages/dyeing6.jpg",
    },
]

export const pedicureWithCoating = [
    {
        id: 1,
        image: "images/servicesImages/pedicureWithCoating1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/pedicureWithCoating2.jpg",
    },
]

export const modeling = [
    {
        id: 1,
        image: "images/servicesImages/modeling1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/modeling2.jpg",
    },
    {
        id: 3,
        image: "images/servicesImages/modeling3.jpg",
    },
    {
        id: 4,
        image: "images/servicesImages/modeling4.jpg",
    },
]

export const varnishCoating = [
    {
        id: 1,
        image: "images/servicesImages/varnishCoating1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/varnishCoating2.jpg",
    },
    {
        id: 3,
        image: "images/servicesImages/varnishCoating3.jpg",
    },
    {
        id: 4,
        image: "images/servicesImages/varnishCoating4.jpg",
    },
]

export const perm = [
    {
        id: 1,
        image: "images/servicesImages/perm1.jpg",
    },
    {
        id: 2,
        image: "images/servicesImages/perm2.jpg",
    },
    {
        id: 3,
        image: "images/servicesImages/perm3.jpg",
    },
]

export const eveningMakeup = [
    {
        id: 1,
        image: "images/servicesImages/eveningMakeup1.jpg",
    },
]