import {React, useEffect} from 'react'
import Title from '../../components/Title/Title'
import '../pages.css'
import styles from './Contacts.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faClock, faMapMarked} from '@fortawesome/free-solid-svg-icons'
import {useLocation } from 'react-router-dom'

const Contacts = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <div className="page-container" id='contacts'>
      <Title name='Контакти'/>
      <section className={styles.container}>
        <h2 className="sectionTitle">Де можна знайти нас в Зінькові</h2>
        <div className={styles.sectionRow}>
        <div className={styles.iframeWrap}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d822.1029120250482!2d34.34633658629172!3d50.2106991450523!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d7d9c631d90ebf%3A0x56f481c3acab8ce!2z0KHQsNC70L7QvSDQutGA0LDRgdC4ICLQndGW0Y8i!5e0!3m2!1suk!2sua!4v1694696652018!5m2!1suk!2sua" width="100%" height="100%" allowfullscreen="" title="niia" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className={styles.contactsInfo}>
          <p>Салон краси Нія має зручне розташування.</p>
          <div className={styles.contactItem}>
            <FontAwesomeIcon icon={faMapMarked} />
            <p>
              <strong>САЛОН НІЯ</strong>
              <br/>
              Зіньків, вулиця Погрібняка, 5-А
            </p>
          </div>
          <div className={styles.contactItem}>
            <FontAwesomeIcon icon={faPhone} />
            <p>
              +38 (068) 777-58-58
              <br/>
              +38 (066) 307-70-52
            </p>
          </div>
          <div className={styles.contactItem}>
            <FontAwesomeIcon icon={faClock} />
            <p>
              Пн. - Сб.: 09:00 - 18:00
              <br/>
              Нед.: 09:00 - 15:00
            </p>
          </div>
        </div>
        </div>
      </section>
    </div>
  )
}

export default Contacts