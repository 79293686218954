import React from 'react'
import Title from '../../components/Title/Title'
import '../pages.css'

const News = () => {
  return (
    <div className="page-container">
      <Title name='Новини салону'/>
    </div>
  )
}

export default News