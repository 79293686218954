import { React, useEffect} from 'react'
import {useLocation } from 'react-router-dom'
import styles from './Main.module.scss'
import mainImage from '../../assets/img/mainImage.png'
import {sliderFirstData, allPartners} from './arrImages'
import { Link } from 'react-router-dom'
import Slider from 'react-slick/lib/slider'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import './Slider/newSlider.css'
import { settings } from './Slider/sliderSettings'
import Gallery from './Gallery/Gallery'
import '../pages.css'

const Main = () => {

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <main className={styles.main} id="main">
      <div className={styles.mainImage}>
        <img src={mainImage} alt=""/>
      </div>
      <section className={styles.contentSection}>
        <div className={styles.headerSection}>
          <h2 className="sectionTitle">Наші послуги</h2>
          <p className={styles.sectionDescription}>Ми пропонуємо якісні послуги краси</p>
          <p className={styles.sectionButton}><Link to="/services">Переглянути всі послуги</Link></p>
        </div>
        <div className={styles.servicesWrapper}>
          <Slider {...settings}>
            {sliderFirstData.map((item)=>(
            <div className={styles.container} key={item.id}>
              <div className={styles.serviceImage}>
              <Link to={{ pathname: "/services", hash: item.hash}}>
                <img src={item.image} alt="" />
              </Link>
              </div>
              <h3 className={styles.serviceTitle}>
                {item.title}
              </h3>
              {/* <div className={styles.serviceDescription}>
                <p>{item.description}</p>
              </div> */}
            </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className={styles.contentSection}>
        <div className={styles.headerSection}>
          <h2 className="sectionTitle">Галерея</h2>
          <p className={styles.sectionDescription}>Погляньте як все виглядає в нашому салоні</p>
        </div>
        <Gallery />
      </section>
      {/* <section className={styles.contentSection}>
        <div className={styles.headerSection}>
          <h2 className="sectionTitle">Новини нашого салону</h2>
          <p className={styles.sectionDescription}>Читайте про останні новини нашого салону!</p>
          <p className={styles.sectionButton}><a href="/">Всі новини</a></p>
        </div>
        <div className={styles.listOfNews}>
            {news.map((item) => (
              <article className={styles.article}>
                <div className={styles.articleImage}>
                  <a href='/'>
                    <img src={item.image} alt="" />
                  </a>
                </div>
                <h3 className={styles.articleTitle}>{item.description}</h3>
              </article>
            ))}
        </div>
      </section> */}
      <section className={styles.contentSection}>
        <div className={styles.headerSection}>
          <h2 className="sectionTitle">Партнери</h2>
          <p className={styles.sectionDescription}>Ми використовуємо лише високоякісну оригінальну продукцію.</p>
        </div>
        <div className={styles.listPartners}>
          <Slider {...settings}>
            {allPartners.map((item)=>(
              <div className={styles.partnerImage}>
                <img src={item.image} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </main>
  )
}

export default Main