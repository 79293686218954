export const sliderFirstData = [
    {
        id: 1,
        image: 'images/heirdo.png',
        hash: 'heirdo',
        title: "Перукарські послуги",
        description: "Стрижка, покраска волосся, догляд за волоссям, зачіски"
    },
    {
        id: 2,
        image: "images/manicure.png",
        hash: 'manicure',
        title: "Манікюр",
        description: "Стрижка, покраска волосся, догляд за волоссям, зачіски"
    },
    {
        id: 3,
        image: "images/pedicure.png",
        hash: 'pedicure',
        title: "Педикюр",
        description: "Стрижка, покраска волосся, догляд за волоссям, зачіски"
    },
    {
        id: 4,
        image: "images/makeup.png",
        hash: 'makeup',
        title: "Візаж",
        description: "Стрижка, покраска волосся, догляд за волоссям, зачіски"
    },
]


export const galleryData = [
    {
        id: 1,
        image: "images/niia1.jpeg",
        description: "Салон Нія",
    },
    {
        id: 2,
        image: "images/niia2.jpeg",
        description: " Салон Нія",
    },
    {
        id: 3,
        image: "images/niia3.jpeg",
        description: "Салон Нія",
    },
    {
        id: 4,
        image: "images/niia4.jpeg",
        description: "Салон Нія",
    },
    {
        id: 5,
        image: "images/niia5.jpeg",
        description: "Салон Нія",
    },
    {
        id: 6,
        image: "images/niia6.jpeg",
        description: "Салон Нія",
    },
    {
        id: 7,
        image: "images/niia7.jpeg",
        description: "Салон Нія",
    },
    {
        id: 8,
        image: "images/niia8.jpeg",
        description: "Салон Нія",
    },
]

export const news = [
    {
        id: 1,
        image: "images/articleOne.jpeg",
        description: "Нові процедури в салоні",
    },
    {
        id: 2,
        image: "images/articleOne.jpeg",
        description: "Нові процедури в салоні",
    },
    {
        id: 3,
        image: "images/articleOne.jpeg",
        description: "Нові процедури в салоні",
    },
    {
        id: 4,
        image: "images/articleOne.jpeg",
        description: "Нові процедури в салоні",
    },
]

export const allPartners = [
    {
        id: 1,
        image: "images/partner.jpg",
    },
    {
        id: 2,
        image: "images/partner2.jpg",
    },
    {
        id: 3,
        image: "images/partner3.jpg",
    },
    {
        id: 4,
        image: "images/partner4.jpg",
    },
]
