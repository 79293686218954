export const menu = [
    {
      title: 'Головна',
      link: '/',
      hash: 'main',
    },
    {
      title: 'Про салон',
      link: '/about',
      hash: 'about',
    },
    {
      title: 'Наші послуги',
      link: '/services',
      hash: 'services',
    },
    // {
    //   title: 'Новини салону',
    //   link: '/news',
    // },
    // {
    //   title: 'Каталог товарів',
    //   link: '/device',
    //   hash: 'device',
    // },
    {
      title: 'Контакти',
      link: '/contacts',
      hash: 'contacts',
    }
  ]